import { combineReducers } from 'redux';
import authReducer from './authReducer';
import sidebarReducer from './sidebar';
import tenantReducer from './tenant';
import chargingStationReducer from './chargingStation';
import vehicleReducer from './vehicle';
import chargerReducer from './charger';
import invoiceReducer from './invoice';
import chargerBookingReducer from './chargerBooking';
import userReducer from './user';
import oemReducer from './oem';
import amenityReducer from './amenity';
import subscriptionReducer from './subscription';
import userProfileReducer from './profile';
import customerReducer from './customer';
import countryReducer from './country';
import stateReducer from './state';
import cityReducer from './city';
import areaReducer from './area';
import notificationReducer from './notification';
import reviewReducer from './review';
import tariffReducer from './tariff';
import vehicleMakeReducer from './vehicleMake';
import vehicleModelReducer from './vehicleModel';
import activityLogReducer from './activityLogReducer';
import electricitySupplierReducer from './electricitySupplier';
import stationAccessTypeReducer from './stationAccessType';
import oemVendorReducer from './oemVendor';
import faqReducer from './faq';
import privacyReducer from './privacy';
import connectorTypeReducer from './connectorType';
import chargeSpeedReducer from './chargeSpeed';
import roleReducer from './role';
import dashboardReducer from './dashboard';
import chargerStateReducer from './chargerState';
import oemErrorCodeReducer from './oemErrorCode';
import stationUnitConsumptionReducer from './stationUnitConsumption';
import consumptionReducer from './consumption';
import chargerStationReducer from './chargerStation';
import b2bStationChargingHistoryReducer from './b2bStationChargingHistory';
import b2cStationChargingHistoryReducer from './b2cStationChargingHistory';
import jsonRequestResponseReportReducer from './jsonRequestResponseReport';
import walletTransactionReportReducer from './walletTransactionReport';
import mostVehicleChargedListReducer from './mostVehicleChargedList';
import appUserDetailsListReducer from './appUserDetailsList';
import bookingHistoryReducer from './bookingHistory';
import stationChargingSummaryReducer from './stationChargingSummary';
import privateStationChargingSummaryReducer from './privateStationChargingSummary';
import publicStationChargingSummaryReducer from './publicStationChargingSummary';

import userChargingHistoryReducer from './userChargingHistory';
import bookingSummaryReportReducer from './bookingSummaryReport';
import moneyTransactionPgReportReducer from './moneyTransactionPgReport';
import permissionReducer from './permissions';
import offersReducer from './offers';
import liveChargingSessionReducer from './liveChargingSession';
import userGroupReducer from './userGroup';
import smsReducer from './sms';
import emailReducer from './email';
import cityByStateReducer from './cityByState';
import moduleReducer from './module';
import rfidReducer from './rfid';
import rfidGroupReducer from './rfidGroup';
import calendarViewReducer from './calendarView';
import masterSettingReducer from './masterSetting';
import userActivityReducer from './userActivity';
import rfidRequestReducer from './rfidRequest';
import allUserInUserGroupReducer from './allUserInUserGroup';
import customerTransactionReducer from './customerTransaction';
import customerBookingReducer from './customerBooking';
import customerProfileReducer from './customerProfile';
import stationByCityReducer from './stationByCity';
import manageRoleReducer from './manageRole';
import stationDownTimeReportReducer from './stationDownTimeReport';
import acMeterReadingReportReducer from './acMeterReadingReport';
import chargerStatusReducer from './chargerStatus';
import areaByCityReducer from './areaByCity';
import reserveSlotsReducer from './reserveSlots';
import partnerManagementReducer from './partnerManagement';
import JobVoucherReducer from './jobVoucher';
import ocpiPullRequestReducer from './ocpiPullRequest';
import invoiceReportReducer from './invoiceReport';
import chargeCoinReducer from './chargeCoin';
import tenantSettingReducer from './tenantSetting';
import chargeCoinTransactionReducer from './chargeCoinTransaction';
import tenantAdminAccessReducer from './tenantAdminAccess';
import capacityUtilisationReducer from './capacity_reducer';
// import negativeValue from './negativereducer';
import downloadingLoaderReducer from './downloadingLoader';
import schedulingSettingReducer from './schedulingSetting';
import scheduleReducer from './schedule';
import communicationManagerReducer from './communicationManager';
import paymentStatusReportReducer from './paymentStatusReport';
import smcGatewayReducer from './smcGateway';
import tagmanagementreducer from './tagmanagement';
import ocppErrorCodeReducer from './ocppErrorCodes';
import vehicleChargingSessionReducer from './vehicleChargingSession';
import faultyBookingReportReducer from './faultyBookingReport';
import ratingReducer from './rating';
import noLoadReportReducer from './noLoadReport';
import chargingSessionReportReducer from './chargingSessionReport';
import fleetMemberReducer from './fleetMembers';
import alldatareportReducer from './alldatareport';
import ocpiPartnerChargingReportReducer from './ocpiPartnerChargingReport';
import accountDeleteRequestReducer from './accountDeleteRequest';
import plantLoadFactorReportReducer from './plantLoadFactorReport';
import ocpiPartnerGroupReducer from './ocpiPartnerGroup';
import chargerLogGraphReducer from './chargerLogGraph';
import reportListReducer from './reportList';
import reportDownloadRequestReducer from './downloadAdvanceReport';
import electricityBillReportReducer from './electricityBillReport';
import HMILCustomChargingReportreducer from './HMILCustomChargingReport';
import voucherReducer from './voucher';
import couponReducer from './coupon';
import couponUsageReportReducer from './couponUsageReportReducer';
import couponPerformanceReportReducer from './couponPerformaceReportReducer';
import partnerChargingSummaryReducer from './partnerChargingSummary';
import partnerLocationReducer from './partnerLocation';

const rootReducer = combineReducers({
  // nega: negativeValue,
  electricityBillReport: electricityBillReportReducer,
  HMILCustomChargingReport: HMILCustomChargingReportreducer,
  ocpiPartnerGroup: ocpiPartnerGroupReducer,
  ocppErrorCodes: ocppErrorCodeReducer,
  auth: authReducer,
  sidebar: sidebarReducer,
  tenant: tenantReducer,
  chargingStation: chargingStationReducer,
  vehicle: vehicleReducer,
  charger: chargerReducer,
  invoice: invoiceReducer,
  chargerBooking: chargerBookingReducer,
  user: userReducer,
  oem: oemReducer,
  amenity: amenityReducer,
  subscription: subscriptionReducer,
  profile: userProfileReducer,
  customer: customerReducer,
  country: countryReducer,
  state: stateReducer,
  city: cityReducer,
  area: areaReducer,
  notification: notificationReducer,
  review: reviewReducer,
  tariff: tariffReducer,
  vehicleMake: vehicleMakeReducer,
  vehicleModel: vehicleModelReducer,
  activityLog: activityLogReducer,
  electricitySupplier: electricitySupplierReducer,
  stationAccessType: stationAccessTypeReducer,
  oemVendor: oemVendorReducer,
  faq: faqReducer,
  privacy: privacyReducer,
  connectorType: connectorTypeReducer,
  chargeSpeed: chargeSpeedReducer,
  role: roleReducer,
  dashboard: dashboardReducer,
  chargerState: chargerStateReducer,
  oemErrorCode: oemErrorCodeReducer,
  stationUnitConsumption: stationUnitConsumptionReducer,
  consumption: consumptionReducer,
  chargerStation: chargerStationReducer,
  b2bStationChargingHistory: b2bStationChargingHistoryReducer,
  b2cStationChargingHistory: b2cStationChargingHistoryReducer,
  jsonRequestResponseReport: jsonRequestResponseReportReducer,
  walletTransactionReport: walletTransactionReportReducer,
  plantLoadFactorReport: plantLoadFactorReportReducer,
  alldatareport: alldatareportReducer,
  ocpiPartnerChargingReport: ocpiPartnerChargingReportReducer,
  mostVehicleChargedList: mostVehicleChargedListReducer,
  appUserDetailsList: appUserDetailsListReducer,
  bookingHistory: bookingHistoryReducer,
  stationChargingSummary: stationChargingSummaryReducer,
  privateStationChargingSummary: privateStationChargingSummaryReducer,
  publicStationChargingSummary: publicStationChargingSummaryReducer,
  userChargingHistory: userChargingHistoryReducer,
  bookingSummaryReport: bookingSummaryReportReducer,
  moneyTransactionPgReport: moneyTransactionPgReportReducer,
  permissions: permissionReducer,
  offers: offersReducer,
  liveChargingSession: liveChargingSessionReducer,
  userGroup: userGroupReducer,
  sms: smsReducer,
  email: emailReducer,
  cityByState: cityByStateReducer,
  module: moduleReducer,
  rfid: rfidReducer,
  rfidGroup: rfidGroupReducer,
  calendarView: calendarViewReducer,
  masterSetting: masterSettingReducer,
  userActivity: userActivityReducer,
  rfidRequest: rfidRequestReducer,
  allUserInUserGroup: allUserInUserGroupReducer,
  customerTransaction: customerTransactionReducer,
  customerBooking: customerBookingReducer,
  customerProfile: customerProfileReducer,
  stationByCity: stationByCityReducer,
  manageRole: manageRoleReducer,
  stationDownTimeReport: stationDownTimeReportReducer,
  acMeterReadingReport: acMeterReadingReportReducer,
  areaByCity: areaByCityReducer,
  chargerStatus: chargerStatusReducer,
  reserveSlots: reserveSlotsReducer,
  partnerManagement: partnerManagementReducer,
  JobVoucher: JobVoucherReducer,
  ocpiPullRequest: ocpiPullRequestReducer,
  invoiceReport: invoiceReportReducer,
  chargeCoin: chargeCoinReducer,
  tenantSetting: tenantSettingReducer,
  chargeCoinTransaction: chargeCoinTransactionReducer,
  tenantAdminAccess: tenantAdminAccessReducer,
  capacityUtilisation: capacityUtilisationReducer,
  downloadingLoader: downloadingLoaderReducer,
  schedulingSetting: schedulingSettingReducer,
  schedule: scheduleReducer,
  communicationManager: communicationManagerReducer,
  paymentStatusReport: paymentStatusReportReducer,
  smcGateway: smcGatewayReducer,
  tagmanagement: tagmanagementreducer,
  vehicleChargingSession: vehicleChargingSessionReducer,
  faultyBookingReport: faultyBookingReportReducer,
  rating: ratingReducer,
  noLoadReport: noLoadReportReducer,
  chargingSessionReport: chargingSessionReportReducer,
  fleetMember: fleetMemberReducer,
  accountDeleteRequest: accountDeleteRequestReducer,
  reportList: reportListReducer,
  reportDownloadRequest: reportDownloadRequestReducer,
  voucher: voucherReducer,
  coupon: couponReducer,
  couponUsage: couponUsageReportReducer,
  couponPerformace: couponPerformanceReportReducer,
  chargerLogGraph: chargerLogGraphReducer,
  partnerChargingSummary: partnerChargingSummaryReducer,
  partnerLocation: partnerLocationReducer,
});

export default rootReducer;
