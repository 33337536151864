import React, { useCallback, useEffect, useState, useContext } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import Card from 'components/inputs/Card';
import SessionHeading from 'components/general/SessionHeading';
import { Row, Col } from 'react-bootstrap';
import TextInput from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import Button from 'components/inputs/Button';
import * as _ from 'lodash';
import { Formik, Form, FieldArray } from 'formik';
// import RadioButton from 'components/inputs/RadioButton';
// import { BiPlus, BiTrash } from 'react-icons/bi';
import { CREATE_CHARGER, UPDATE_CHARGER, GET_CHARGER } from 'actions/charger';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { FETCH_OEM } from 'actions/oem';
import { FETCH_TARIFF } from 'actions/tariff';
// import { WeekEndOptions, WeekDayOptions } from '../stations/StationForm';
import CheckBox from 'components/inputs/CheckBox';
import { FETCH_CHARGE_SPEED } from 'actions/chargeSpeed';
import { FETCH_OEM_VENDOR } from 'actions/oemVendor';
import DatePickerInput from 'components/inputs/DatePickerInput';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Footer from 'components/general/Footer';
import { GET_ADD_ONS, GET_STATION_CHARGERS, MANAGE_ADD_ONS, MANAGE_STATION_CHARGERS, UPDATE_STATUS_CODE } from 'components/common/constant';
import { NavContext } from 'components/privateRoute/PrivateRoute';
// import { toast } from 'react-toastify';

const ChargerForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { chargerId } = useParams();
  const { navigateTo } = useContext(NavContext);
  const queryParams = new URLSearchParams(window.location.search);
  const chargingStationId = queryParams.get('station');

  const allStations = useSelector((state) => state.chargingStation.chargingStations);
  const allOems = useSelector((state) => state.oem.oems);
  const allOemVendor = useSelector((state) => state.oemVendor.oemVendors);
  const allChargerSpeed = useSelector((state) => state.chargeSpeed.chargeSpeed);
  // const isLoading = useSelector((state) => state.charger.isLoading);
  const chargerDetail = useSelector((state) => state.charger.chargerDetails);
  const allTariffList = useSelector((state) => state.tariff.tariff);
  const profileDetail = useSelector((state) => state.profile.userProfile);
  const tenantId = _.get(profileDetail, 'tenant.id', '');
  const currentUserName = _.get(profileDetail, 'name', '');
  const supportApp = _.get(profileDetail, 'tenant.support_cz_app_booking', '');
  const oemPage = useSelector((state) => state.oemVendor.page);
  const tariffPage = useSelector((state) => state.tariff.page);
  const oemTotalPage = useSelector((state) => state.oemVendor.totalPages);
  const tariffTotalPage = useSelector((state) => state.tariff.totalPages);
  // const [generateChargerId, setGenerateChargerId] = useState('');
  const [isDraft, setDraft] = useState(false);

  const editChargerDetail = useCallback(
    (data) =>
      dispatch({
        type: UPDATE_CHARGER,
        payload: data,
        cb: (res) => {
          if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
            setTimeout(() => {
              navigateTo(`/charger/${chargerId}`);
            }, 1000);
          }
        },
      }),
    [chargerId]
  );
  const rolePermissionData = useSelector((state) => state.manageRole.manageRoles);

  const getChargerDetail = useCallback((id) => {
    dispatch({ type: GET_CHARGER, payload: id });
  }, []);

  const getAllChargerSpeed = useCallback(() => dispatch({ type: FETCH_CHARGE_SPEED }), []);

  const getChargeSpotByOem = useCallback((id = '') => {
    dispatch({ type: FETCH_OEM, payload: { vendor: id } });
  }, []);

  const getTariffByChargeSpot = useCallback((data = {}) => {
    dispatch({ type: FETCH_TARIFF, payload: data });
  }, []);

  const getChargingStations = useCallback(() => {
    const data = {
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: data });
  }, []);

  const getOemVendor = useCallback((data = {}) => {
    dispatch({ type: FETCH_OEM_VENDOR, payload: data });
  }, []);

  const getAddOns =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_ADD_ONS),
      (data) => data.name
    );

  const manageAddOns =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_ADD_ONS),
      (data) => data.name
    );

  const manageStationCharger =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === MANAGE_STATION_CHARGERS),
      (data) => data.name
    );

  const getStationCharger =
    !_.isEmpty(rolePermissionData) &&
    _.map(
      _.filter(rolePermissionData[0].permissions, (item) => item.name === GET_STATION_CHARGERS),
      (data) => data.name
    );

  useEffect(() => {
    getOemVendor({ limit: 999 });
    getAllChargerSpeed();
    getChargingStations();
    // const generateId = 'Ch' + Math.random().toString().substring(12);
    // setGenerateChargerId(generateId);
  }, []);

  useEffect(() => {
    if (chargerId) {
      getChargerDetail(chargerId);
    }
    if (chargerId && !_.isEmpty(chargerDetail)) {
      getChargeSpotByOem(_.get(chargerDetail, 'oem.vendor.id'));
      getTariffByChargeSpot({ oem: _.get(chargerDetail, 'oem.id'), limit: 999 });
    }
  }, [chargerId, _.isEmpty(chargerDetail) && chargerDetail]);

  const ChargerSchema = Yup.object({
    charger_id: Yup.string().required(t('chargerForm.chargerId')).strict(true).trim(t('chargerForm.space')),
    // description: Yup.string().required('Description is required').strict(true).trim('Space is not allowed'),
    oem_vendor: Yup.string().required(t('chargerForm.oemVendor')),
    oem: Yup.string().required(t('chargerForm.oem')),
    charging_station: Yup.string().required(t('chargerForm.chargingStation')).strict(true).trim(t('chargerForm.space')),
    tariff: Yup.string().required(t('chargerForm.tariff')),
    serial_number: Yup.string(),
    // timings: Yup.array().of(
    //   Yup.object().shape({
    //     day: Yup.string().required('Day is required'),
    //     from: Yup.string().required('Time is required'),
    //     to: Yup.string().required('Time is required'),
    //   })
    // ),
    // plugs: Yup.array().of(
    //   Yup.object().shape({
    //     name: Yup.string().required('Name is required').strict(true).trim('Space is not allowed'),
    //     connector_id: Yup.string()
    //       .required('ConnectorId is required')
    //       .strict(true)
    //       .trim('Space is not allowed')
    //       .max(1, 'No more than one character')
    //       .matches(/^[1-5]+$/, 'Only 1 to 5 numbers are allowed'),
    //   })
    // ),
    // per_unit_rates: Yup.string().required('Rate per unit is required'),
    charging_speed: Yup.string().required(t('chargerForm.chargingSpeed')),
    // comments: Yup.string().required('Comment is required'),
  });

  const initialValues = chargerId
    ? {
        ...chargerDetail,
        charging_station: _.get(chargerDetail, 'charging_station.id', ''),
        // is_chargecoin_disabled: _.get(_.find(allStations, { id: chargingStationId }), 'is_chargecoin_disabled', ''),
        // tenant: _.get(chargerDetail, 'tenant.id', ''),
        oem: _.get(chargerDetail, 'oem.id', ''),
        oem_vendor: _.get(chargerDetail, 'oem.vendor.id', ''),
        tariff: _.get(chargerDetail, 'tarrif.id', ''),
        charger_id: _.get(chargerDetail, 'charger_id', ''),
        installation_date: chargerDetail.installation_date ? moment(_.get(chargerDetail, 'installation_date')).format('YYYY-MM-DD') : '',
        // plugs: _.map(_.get(chargerDetail, 'plugs', []), (item) => {
        //   return {
        //     name: _.get(item, 'name'),
        //     connector_id: _.get(item, 'connector_id'),
        //     oem_ref: _.get(item, 'oem_ref'),
        //     status: _.get(item, 'status',''),
        //   };
        // }),
      }
    : {
        description: '',
        charger_id: '',
        oem: '',
        // plugs: [{ name: '', connector_id: '', status: '', oem_ref: '', tarrif: '' }],
        plugs: [],
        is_active: true,
        tenant: '',
        installation_date: '',
        charging_station: chargingStationId ? _.get(_.find(allStations, { id: chargingStationId }), 'id', '') : '',
        // access_type: '',
        timing_type: 'custom',
        // timings: [{ day: '', from: '', to: '', access: 'pub', status: 'Available' }],
        // per_unit_rates: '',
        parking_restrictions: false,
        is_superfast_mode_supported: false,
        is_duplicate_transaction_mode_supported: false,
        is_ping_pong_supported: false,
        auto_charge_supported: false,
        otp_verification: false,
        rfid_verification: false,
        wss_supported: false,
        is_chargecoin_disabled: _.get(_.find(allStations, { id: chargingStationId }), 'is_chargecoin_disabled', ''),
        comments: '',
        cooldown_time: '',
        charging_speed: '',
        oem_vendor: '',
        tariff: '',
        serial_number: '',
        load_limit: '',
        settings: {
          ocpp_details: {},
          current_firmware: '1.1',
        },
      };

  const addCharger = useCallback((data) => {
    dispatch({
      type: CREATE_CHARGER,
      payload: data,
      cb: (res) => {
        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
          if (chargingStationId) {
            setTimeout(() => {
              navigateTo(`/stations/${chargingStationId}`);
            }, 1000);
          } else {
            setTimeout(() => {
              navigateTo('/stations');
            }, 1000);
          }
        }
      },
    });
  }, []);

  // const onCoinClick = () => {
  //   const chargecoin_val = initialValues.is_chargecoin_disabled;
  //   console.log(chargecoin_val);
  //   if (chargecoin_val) toast.error('ChargerCoin Is Disabled For This Station ');
  // };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title={`${chargerId ? 'Edit' : 'Add'} ${t('header.charger')}`} />
        <div className="page-content-wrapper scrollable">
          <div className="add-charger-page__main">
            <Card className="charger-form--card">
              <Formik
                enableReinitialize={!!chargerId}
                initialValues={initialValues}
                validationSchema={ChargerSchema}
                onSubmit={(values, { setSubmitting }) => {
                  values['is_active'] = !isDraft;
                  const uniqId = values.charger_id;
                  if (chargerId) {
                    //edit charger
                    const editedChargerData = {
                      ...values,
                      chargerId: values.id,
                      id: uniqId,
                      timings: _.map(values.timings, (item) => _.omit(item, ['_id'])),
                      plugs: _.map(values.plugs, (item) => _.omit(item, ['_id', 'errorCode', 'errorMessage', 'external_tarrif', 'oem_ref'])),
                      // tenant: values.tenant ? values.tenant : tenantId,
                      tarrif: values.tariff,
                    };
                    const newData = _.omit(editedChargerData, [
                      'settings',
                      'timings',
                      'timings',
                      'id',
                      'is_errored',
                      'old_status',
                      'is_auth_supported',
                      'oem_vendor',
                      'tariff',
                      'ocpp_url',
                      'last_active',
                      'tenant',
                      'createdAt',
                      'updatedAt',
                      'times_sent_trigger',
                      'is_external_charger',
                      'external_evse_id',
                      'ocpiCredential',
                      'is_auto_charge_supported',
                      'last_session_happened',
                    ]);
                    editChargerDetail(newData);
                  } else {
                    //add charger
                    const chargerDetail = {
                      ...values,
                      tarrif: values.tariff,
                      // installation_date: moment(_.get(values, 'installation_date')).format('YYYY-MM-DD'),
                      plugs: _.map(values.plugs, (item) => _.omit(item, ['oem_ref'])),
                    };
                    const addChargerData = _.omit(chargerDetail, ['tariff', 'oem_vendor']);
                    addCharger({ ...addChargerData, tenant: tenantId });
                  }
                  setSubmitting(false);
                }}
              >
                {({ values, handleSubmit, handleChange, setFieldValue, isSubmitting, errors, touched }) => (
                  <Form onSubmit={handleSubmit}>
                    <div>
                      <SessionHeading text={t('addChargerForm.chargerInformation')} />
                      <div className="charger-form--block">
                        <Row>
                          <Col lg={12}>
                            <Select
                              isRequired
                              label={t('addChargerForm.stationSituated')}
                              options={_.map(allStations, (station) => {
                                return { label: station.name, value: station.id };
                              })}
                              placeholder={t('placeHolder.selectStation')}
                              name="charging_station"
                              value={values.charging_station}
                              onChange={(val) => setFieldValue(`charging_station`, val)}
                              error={errors.charging_station && touched.charging_station ? errors.charging_station : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              as="textarea"
                              rows="4"
                              inputClass="scrollable"
                              placeholder={t('placeHolder.selectDescription')}
                              label={t('addChargerForm.description')}
                              name="description"
                              value={values.description}
                              onChange={handleChange}
                              // error={errors.description && touched.description ? errors.description : null}
                            />
                          </Col>
                          <Col lg={6} md={12}>
                            <div className="parking-checkbox-input__block">
                              <label htmlFor="parking_restriction" className="parking--label">
                                {t('addChargerForm.parkingRestrictions')}
                              </label>
                              <CheckBox
                                name="parking_restriction"
                                checked={values.parking_restrictions}
                                onChange={(e) => setFieldValue('parking_restrictions', e.target.checked)}
                              />
                              <br />
                              <label htmlFor="superfast_mode_supported" className="parking--label">
                                {t('addChargerForm.superFastMode')}
                              </label>
                              <CheckBox
                                name="superfast_mode_supported"
                                checked={values.is_superfast_mode_supported}
                                onChange={(e) => setFieldValue('is_superfast_mode_supported', e.target.checked)}
                              />
                              <br />
                              <label htmlFor="duplicate_transaction_mode_supported" className="parking--label">
                                {t('addChargerForm.duplicateTransactionMode')}
                              </label>
                              <CheckBox
                                name="duplicate_transaction_mode_supported"
                                checked={values.is_duplicate_transaction_mode_supported}
                                onChange={(e) => setFieldValue('is_duplicate_transaction_mode_supported', e.target.checked)}
                              />
                              <br />
                              <label htmlFor="auto_charge_supported" className="parking--label">
                                {t('addChargerForm.autoChargingSupport')}
                              </label>
                              <CheckBox
                                name="auto_charge_supported"
                                checked={values.auto_charge_supported}
                                onChange={(e) => setFieldValue('auto_charge_supported', e.target.checked)}
                              />
                              <br />
                              <label htmlFor="ping_pong_supported" className="parking--label">
                                {t('addChargerForm.pingPongSupported')}
                              </label>
                              <CheckBox
                                name="ping_pong_supported"
                                checked={values.is_ping_pong_supported}
                                onChange={(e) => setFieldValue('is_ping_pong_supported', e.target.checked)}
                              />
                              <div className="otp-checkbox">
                                <label htmlFor="otp_verification" className="parking--label">
                                  {t('addChargerForm.otp')}
                                </label>
                                <CheckBox
                                  name="otp_verification"
                                  checked={values.otp_verification}
                                  onChange={(e) => setFieldValue('otp_verification', e.target.checked)}
                                />
                                <br />
                                <label htmlFor="rfid_verification" className="parking--label">
                                  {t('addChargerForm.rifd')}
                                </label>
                                <CheckBox
                                  name="rfid_verification"
                                  checked={values.rfid_verification}
                                  onChange={(e) => setFieldValue('rfid_verification', e.target.checked)}
                                />
                              </div>
                              <label htmlFor="wss_supported" className="parking--label">
                                {t('addChargerForm.wssSupported')}
                              </label>
                              <CheckBox
                                name="wss_supported"
                                checked={values.wss_supported}
                                onChange={(e) => setFieldValue('wss_supported', e.target.checked)}
                              />
                              <br />
                            </div>
                            {/* <div className="parking-checkbox-input__block">
                              <label htmlFor="superfast_mode_supported" className="parking--label">
                                Superfast mode supported :
                              </label>
                              <CheckBox
                                name="superfast_mode_supported"
                                checked={values.is_superfast_mode_supported}
                                onChange={(e) => setFieldValue('is_superfast_mode_supported', e.target.checked)}
                              />
                            </div> */}
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              isRequired
                              label={t('addChargerForm.chargerID')}
                              name="charger_id"
                              value={values.charger_id}
                              onChange={handleChange}
                              error={errors.charger_id && touched.charger_id ? errors.charger_id : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              post_text={t('addChargerForm.mins')}
                              label={t('addChargerForm.cooldownTime')}
                              name="cooldown_time"
                              value={values.cooldown_time}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <Row>
                              <Col lg={12} md={12}>
                                <Select
                                  isRequired
                                  label={t('addChargerForm.oem')}
                                  options={_.map(allOemVendor, (oemItem) => {
                                    return { label: oemItem.name, value: oemItem.id };
                                  })}
                                  placeholder={t('placeHolder.selectOEMName')}
                                  name="oem_vendor"
                                  value={values.oem_vendor}
                                  onMenuScrollDown={true}
                                  getDataOnScrollDown={getOemVendor}
                                  page={oemPage}
                                  totalPage={oemTotalPage}
                                  onChange={(val) => {
                                    getChargeSpotByOem(val);
                                    setFieldValue(`oem_vendor`, val);
                                    setFieldValue(`oem`, '');
                                    setFieldValue(`plugs`, []);
                                  }}
                                  error={errors.oem_vendor && touched.oem_vendor ? errors.oem_vendor : null}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} md={6}>
                            <Row>
                              <Col lg={12} md={12}>
                                <Select
                                  isRequired
                                  label={t('addChargerForm.chargespotName')}
                                  options={_.map(allOems, (item) => {
                                    return { label: item.name, value: item.id };
                                  })}
                                  placeholder={t('placeHolder.selectChargeSport')}
                                  name="oem"
                                  value={values.oem}
                                  onChange={(val) => {
                                    setFieldValue(`oem`, val);
                                    getTariffByChargeSpot({ oem: val, limit: 999 });
                                    const oemPlugs = _.get(_.find(allOems, { id: val }), 'plugs');
                                    if (_.isEmpty(oemPlugs)) {
                                      setFieldValue('plugs', []);
                                    } else {
                                      const plugData = _.map(oemPlugs, (item) => {
                                        return { name: item.name, connector_id: item.number, oem_ref: item._id, status: 'Unavailable' };
                                      });
                                      setFieldValue('plugs', plugData);
                                    }
                                  }}
                                  error={errors.oem && touched.oem ? errors.oem : null}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} md={12}>
                            <Select
                              isRequired
                              label={t('addChargerForm.tariff')}
                              options={_.map(allTariffList, (item) => {
                                return { label: item.name, value: item.id };
                              })}
                              placeholder={t('placeHolder.selectTarrif')}
                              name="tarrif"
                              value={values.tariff}
                              onMenuScrollDown={true}
                              getDataOnScrollDown={(data) => getTariffByChargeSpot({ ...data, oem: values.oem })}
                              page={tariffPage}
                              totalPage={tariffTotalPage}
                              onChange={(val) => {
                                setFieldValue(`tariff`, val);
                              }}
                              error={errors.tariff && touched.tariff ? errors.tariff : null}
                            />
                          </Col>
                          {/* <Col lg={6} md={6}>
                            <TextInput
                              pre_text="$"
                              post_text="/ Unit"
                              label="Per unit Rate"
                              name="per_unit_rates"
                              value={values.per_unit_rates}
                              onChange={handleChange}
                              error={errors.per_unit_rates && touched.per_unit_rates ? errors.per_unit_rates : null}
                            />
                          </Col> */}
                          {/* <Col lg={6} md={6}>
                            <TextInput
                              type="number"
                              post_text="mins"
                              label="Maximum Charging time"
                              name="maxChargeTime"
                              value={values.maxChargeTime}
                              onChange={handleChange}
                            />
                          </Col> */}

                          {/* <Col lg={6} md={6}>
                            <TextInput
                              post_text="kWh"
                              label="Energy Limit"
                              name="energy_limits"
                              value={values.energy_limits}
                              onChange={handleChange}
                              error={errors.energy_limits && touched.energy_limits ? errors.energy_limits : null}
                            />
                          </Col> */}
                          <Col lg={6} md={6}>
                            <Select
                              isRequired
                              label={t('addChargerForm.chargingSpeed')}
                              options={_.map(allChargerSpeed, (item) => {
                                return { label: item.name, value: item.name };
                              })}
                              placeholder={t('placeHolder.selectSpeed')}
                              name="charging_speed"
                              value={values.charging_speed}
                              onChange={(val) => setFieldValue(`charging_speed`, val)}
                              error={errors.charging_speed && touched.charging_speed ? errors.charging_speed : null}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <DatePickerInput
                              placeholder={t('placeHolder.selectInstallDate')}
                              label={t('addChargerForm.installationDate')}
                              name="installation_date"
                              value={_.get(values, 'installation_date') || ''}
                              onChange={(dateString) => setFieldValue('installation_date', dateString)}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput
                              label={t('addChargerForm.serialNumber')}
                              name="serial_number"
                              value={values.serial_number}
                              onChange={handleChange}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <TextInput label={t('addChargerForm.loadLimit')} name="load_limit" value={values.load_limit} onChange={handleChange} />
                          </Col>
                          {supportApp ? (
                            <Col>
                              <div className="parking-checkbox-input__block">
                                <label htmlFor="is_chargecoin_disabled" className="parking--label">
                                  {t('addChargerForm.isDisableChargecoin')}
                                </label>
                                <CheckBox
                                  name="is_chargecoin_disabled"
                                  checked={values.is_chargecoin_disabled}
                                  onChange={(e) => setFieldValue('is_chargecoin_disabled', e.target.checked)}
                                />
                              </div>
                            </Col>
                          ) : (
                            ''
                          )}
                          {/* <Col lg={6} md={6}>
                            <Select
                              isRequired
                              label="Access Type"
                              options={[
                                { label: 'Public', value: 'Public' },
                                { label: 'Private', value: 'Private' },
                              ]}
                              placeholder="Select Access Type"
                              name="access_type"
                              value={values.access_type}
                              onChange={(val) => setFieldValue(`access_type`, val)}
                            />
                          </Col> */}
                          {/* <Col lg={6} md={6}>
                            <Select
                              label="Charger Status"
                              options={[
                                { label: 'Available', value: 'Available' },
                                // { label: 'Unavailable', value: 'Unavailable' },
                              ]}
                              placeholder="Select Access"
                              name="charger_status"
                              value={values.charger_status}
                              onChange={(val) => setFieldValue(`charger_status`, val)}
                              error={errors.charger_status && touched.charger_status ? errors.charger_status : null}
                            />
                          </Col> */}
                        </Row>
                      </div>
                    </div>
                    {/* <div>
                      <SessionHeading text="Operational Timings" />
                      <div className="charger-form--block">
                        <div className="checkbox-input--group">
                          <div id="amenities" className="checkbox--input-items">
                            <Row>
                              <Col xxl={6} xl={8} md={12}>
                                <Row className="m-0">
                                  <Col xl={2} lg={2} md={6} className="p-0">
                                    <RadioButton
                                      checked={values.timing_type === 'daily'}
                                      onChange={() => {
                                        setFieldValue('timing_type', 'daily');
                                        setFieldValue(`timings[0].day`, 'daily');
                                      }}
                                    >
                                      Daily
                                    </RadioButton>
                                  </Col>
                                  <Col xl={4} lg={4} md={6} className="p-0">
                                    <RadioButton checked={values.timing_type === 'weekday'} onChange={() => setFieldValue('timing_type', 'weekday')}>
                                      Weekdays only
                                    </RadioButton>
                                  </Col>
                                  <Col xl={4} lg={4} md={6} className="p-0">
                                    <RadioButton
                                      checked={values.timing_type === 'weekends'}
                                      onChange={() => setFieldValue('timing_type', 'weekends')}
                                    >
                                      Weekends only
                                    </RadioButton>
                                  </Col>
                                  <Col xl={2} lg={2} md={6} className="p-0">
                                    <RadioButton checked={values.timing_type === 'custom'} onChange={() => setFieldValue('timing_type', 'custom')}>
                                      Custom
                                    </RadioButton>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        {values.timing_type && (
                          <div className="time-block__field-inputs">
                            <FieldArray
                              name="timings"
                              render={(arrayHelpers) => (
                                <>
                                  {_.map(values.timings, (field, index) => (
                                    <>
                                      <Row key={`charger-timimg-${index}`}>
                                        {values.timing_type !== 'daily' && (
                                          <Col xl={3} lg={5} md={4}>
                                            <Select
                                              label="Day"
                                              name="day"
                                              placeholder="Select Day"
                                              value={field.day || ''}
                                              options={values.timing_type === 'weekends' ? WeekEndOptions : WeekDayOptions}
                                              onChange={(val) => setFieldValue(`timings[${index}].day`, val)}
                                              error={
                                                getIn(errors, `timings.${index}.day`) && getIn(touched, `timings.${index}.day`)
                                                  ? getIn(errors, `timings.${index}.day`)
                                                  : null
                                              }
                                            />
                                          </Col>
                                        )}
                                        <Col xl={4} lg={7} md={8}>
                                          <Row>
                                            <Col>
                                              <div className="position-relative">
                                                <TextInput
                                                  type="time"
                                                  label="Hour from"
                                                  name="from"
                                                  value={field.from}
                                                  onChange={(e) => setFieldValue(`timings[${index}].from`, e.target.value)}
                                                  error={
                                                    getIn(errors, `timings[${index}].from`) && getIn(touched, `timings[${index}].from`)
                                                      ? getIn(errors, `timings[${index}].from`)
                                                      : null
                                                  }
                                                />
                                              </div>
                                            </Col>
                                            <Col>
                                              <TextInput
                                                type="time"
                                                label="Hour to"
                                                name="to"
                                                value={field.to}
                                                onChange={(e) => setFieldValue(`timings[${index}].to`, e.target.value)}
                                                error={
                                                  getIn(errors, `timings[${index}].to`) && getIn(touched, `timings[${index}].to`)
                                                    ? getIn(errors, `timings[${index}].to`)
                                                    : null
                                                }
                                              />
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col xl={2} lg={5} md={5}>
                                          <TextInput
                                            label="Access"
                                            name="access"
                                            disabled={true}
                                            value={field.access || ''}
                                            onChange={(e) => setFieldValue(`timings[${index}].access`, e.target.value)}
                                            // error={
                                            //   getIn(errors, `timings[${index}].access`) && getIn(touched, `timings[${index}].access`)
                                            //     ? getIn(errors, `timings[${index}].access`)
                                            //     : null
                                            // }
                                          />
                                        </Col>
                                        <Col xl={2} lg={5} md={5}>
                                          <TextInput
                                            label="status"
                                            name="status"
                                            disabled={true}
                                            value={field.status || ''}
                                            onChange={(e) => setFieldValue(`timings[${index}].status`, e.target.value)}
                                            // error={
                                            //   getIn(errors, `timings[${index}].status`) && getIn(touched, `timings[${index}].status`)
                                            //     ? getIn(errors, `timings[${index}].status`)
                                            //     : null
                                            // }
                                          />
                                        </Col>
                                        <Col xl={1} md={2} lg={2} className="d-flex align-items-center">
                                          {index > 0 && (
                                            <Button className="field--btn" onClick={() => arrayHelpers.remove(index)}>
                                              <BiTrash size={26} />
                                            </Button>
                                          )}
                                        </Col>
                                      </Row>
                                    </>
                                  ))}
                                  {!(values.timing_type === 'daily') && (
                                    <div>
                                      <Button
                                        className="field--btn"
                                        onClick={() =>
                                          arrayHelpers.insert(values.timings.length, {
                                            day: '',
                                            from: moment().format('h:mm a'),
                                            to: moment().format('h:mm a'),
                                            access: 'pub',
                                            status: 'Available',
                                          })
                                        }
                                      >
                                        <BiPlus size={22} className="plus--icon" /> Add
                                      </Button>
                                    </div>
                                  )}
                                </>
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </div> */}
                    <div>
                      <SessionHeading text={t('addChargerForm.plugsInformation')} />
                      <div className="charger-form--block">
                        <div className="plug-block__field-inputs">
                          <FieldArray
                            name="plugs"
                            render={() => (
                              <>
                                {_.map(values.plugs, (field, index) => (
                                  <div className="charger-plug--item">
                                    <Row key={`charger-plug-${index}`}>
                                      <Col lg={4} md={6}>
                                        <TextInput
                                          disabled
                                          label={t('addChargerForm.name')}
                                          name="name"
                                          value={field.name || ''}
                                          onChange={(e) => setFieldValue(`plugs[${index}].name`, e.target.value)}
                                          // error={
                                          //   getIn(errors, `plugs[${index}].name`) && getIn(touched, `plugs[${index}].name`)
                                          //     ? getIn(errors, `plugs[${index}].name`)
                                          //     : null
                                          // }
                                        />
                                      </Col>
                                      <Col lg={4} md={6}>
                                        <TextInput
                                          disabled
                                          label={t('addChargerForm.connectorId')}
                                          name="connector_id"
                                          value={field.connector_id || ''}
                                          onChange={(e) => setFieldValue(`plugs[${index}].connector_id`, e.target.value)}
                                          // error={
                                          //   getIn(errors, `plugs[${index}].connector_id`) && getIn(touched, `plugs[${index}].connector_id`)
                                          //     ? getIn(errors, `plugs[${index}].connector_id`)
                                          //     : null
                                          // }
                                        />
                                      </Col>
                                      <Col lg={4} md={12}>
                                        <Select
                                          label={t('addChargerForm.tariff')}
                                          options={_.map(allTariffList, (item) => {
                                            return { label: item.name, value: item.id };
                                          })}
                                          placeholder={t('placeHolder.selectTarrif')}
                                          name="tarrif"
                                          value={field.tarrif || ''}
                                          onMenuScrollDown={true}
                                          getDataOnScrollDown={(data) => getTariffByChargeSpot({ ...data, oem: values.oem })}
                                          page={tariffPage}
                                          totalPage={tariffTotalPage}
                                          onChange={(val) => {
                                            setFieldValue(`plugs[${index}].tarrif`, val);
                                          }}
                                        />
                                      </Col>
                                      {/*<Col lg={4} md={4}>*/}
                                      {/*  <Select*/}
                                      {/*    label="Status"*/}
                                      {/*    name="status"*/}
                                      {/*    placeholder="Select status"*/}
                                      {/*    value={field.status || ''}*/}
                                      {/*    options={[*/}
                                      {/*      { label: 'Unavailable', value: 'Unavailable' },*/}
                                      {/*    ]}*/}
                                      {/*    onChange={(val) => setFieldValue(`plugs[${index}].status`, val)}*/}
                                      {/*    // error={*/}
                                      {/*    //   getIn(errors, `plugs.${index}.status`) && getIn(touched, `plugs.${index}.status`)*/}
                                      {/*    //     ? getIn(errors, `plugs.${index}.status`)*/}
                                      {/*    //     : null*/}
                                      {/*    // }*/}
                                      {/*  />*/}
                                      {/*</Col>*/}
                                      {/*<Col lg={4} md={4}>*/}
                                      {/*  <Select*/}
                                      {/*    isDisabled*/}
                                      {/*    label="OEM Plug"*/}
                                      {/*    options={_.map(_.get(_.find(allOems, { id: values.oem }), 'plugs', []), (item) => {*/}
                                      {/*      return { label: item.name, value: item._id };*/}
                                      {/*    })}*/}
                                      {/*    placeholder="Oem Plug"*/}
                                      {/*    name="oem_ref"*/}
                                      {/*    value={field.oem_ref || ''}*/}
                                      {/*    onChange={(e) => setFieldValue(`plugs[${index}].oem_ref`, e)}*/}
                                      {/*    // error={*/}
                                      {/*    //   getIn(errors, `plugs[${index}].oem_ref`) && getIn(touched, `plugs[${index}].oem_ref`)*/}
                                      {/*    //     ? getIn(errors, `plugs[${index}].oem_ref`)*/}
                                      {/*    //     : null*/}
                                      {/*    // }*/}
                                      {/*  />*/}
                                      {/*</Col>*/}
                                      {/*<Col lg={4} md={4}>*/}
                                      {/*  <Select*/}
                                      {/*    label="Tariff"*/}
                                      {/*    options={_.map(allTariffList, (item) => {*/}
                                      {/*      return { label: item.name, value: item.id };*/}
                                      {/*    })}*/}
                                      {/*    placeholder="Select Tariff"*/}
                                      {/*    name="tarrif"*/}
                                      {/*    value={field.tarrif || ''}*/}
                                      {/*    onChange={(e) => setFieldValue(`plugs[${index}].tarrif`, e)}*/}
                                      {/*    error={*/}
                                      {/*      getIn(errors, `plugs[${index}].tarrif`) && getIn(touched, `plugs[${index}].tarrif`)*/}
                                      {/*        ? getIn(errors, `plugs[${index}].tarrif`)*/}
                                      {/*        : null*/}
                                      {/*    }*/}
                                      {/*  />*/}
                                      {/*</Col>*/}
                                      {/* <Col xl={1} lg={1} md={1} className="d-flex align-items-center">
                                        <Button className="field--btn" onClick={() => arrayHelpers.remove(index)}>
                                          <BiTrash size={26} />
                                        </Button>
                                      </Col> */}
                                    </Row>
                                  </div>
                                ))}
                                {/* <div>
                                  <Button
                                    className="field--btn"
                                    onClick={() =>
                                      arrayHelpers.insert(values.plugs.length, {
                                        name: '',
                                        connector_id: '',
                                        status: '',
                                        oem_ref: '',
                                        // tarrif: '',
                                      })
                                    }
                                  >
                                    <BiPlus size={22} className="plus--icon" /> Add
                                  </Button>
                                </div> */}
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <SessionHeading text={t('addChargerForm.comments/Notes')} />
                      <div className="charger-form--block">
                        <Row>
                          <Col>
                            <TextInput
                              // isRequired
                              as="textarea"
                              rows="6"
                              inputClass="scrollable"
                              label={t('addChargerForm.commentsforChargingStation')}
                              name="comments"
                              value={values.comments}
                              onChange={handleChange}
                              // error={errors.comments && touched.comments ? errors.comments : null}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    {!_.isEmpty(manageAddOns) ||
                    (!_.isEmpty(getAddOns) && !_.isEmpty(manageAddOns)) ||
                    !_.isEmpty(manageStationCharger) ||
                    (!_.isEmpty(getStationCharger) && !_.isEmpty(manageStationCharger)) ? (
                      <div className="save-draft-btn--block">
                        <Button type="submit" className="save-btn draft--btn" disabled={isSubmitting} onClick={() => setDraft(true)}>
                          {t('button.saveDraft')}
                        </Button>
                        <Button type="submit" className="save-btn" disabled={isSubmitting} onClick={() => setDraft(false)}>
                          {t('button.savePublish')}
                        </Button>
                      </div>
                    ) : (
                      <div className="save-draft-btn--block">
                        <Button className="save-btn draft--btn"> {t('button.saveDraft')}</Button>
                        <Button className="save-btn"> {t('button.savePublish')}</Button>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
          {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

export default ChargerForm;
