import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsDownload, BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import SearchBox from 'components/general/SearchBox';
import { OCPI_BOOKING, DOWNLOAD_OCPI_BOOKING } from 'actions/dataReport';
import { FETCH_CPO } from 'actions/partnerManagement';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { IoSearchOutline } from 'react-icons/io5';
import DateTimePicker from 'components/inputs/DateTimePicker';
import fileDownload from 'js-file-download';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
import ReactPagination from 'components/general/ReactPagination';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FaFilter } from 'react-icons/fa';
import Footer from 'components/general/Footer';

const ocpiPartnerChargingReport = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [sortByItem] = useState({ item: '', order: '' });
    const [searchText, setSearchText] = useState('');
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [startDate, setStartDate] = useState(moment().startOf('month'));
    const [endDate, setEndDate] = useState(moment(new Date()).endOf('day'));
    const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
    const [advanceFilterData, setAdvanceFilterData] = useState({});
    const [changeStation, setChangeStation] = useState(false);
    // const [changeWalletType, setChangeWalletType] = useState(false);
    // const [changePaymentType, setChangePaymentType] = useState(false);
    const ocpidatalist = useSelector((state) => state.ocpiPartnerChargingReport.ocpiPartnerChargingReport);
    const isLoader = useSelector((state) => state.ocpiPartnerChargingReport.isLoading);
    const page = useSelector((state) => state.ocpiPartnerChargingReport.page);
    const totalData = useSelector((state) => state.ocpiPartnerChargingReport.total);
    const limit = useSelector((state) => state.ocpiPartnerChargingReport.limit);
    const totalPages = useSelector((state) => state.ocpiPartnerChargingReport.totalPages);
    const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
    const profileData = useSelector((state) => state.profile.userProfile);
    const userProfileStations = profileData.charging_stations;
    const currentUserName = _.get(profileData, 'name', '');
    const ocpidata = useSelector((state) => state.partnerManagement.partnerDetails)
    const ocpiPage = useSelector((state) => state.partnerManagement.page);
    const ocpiTotalPages = useSelector((state) => state.partnerManagement.totalPages);

    const ocpiPartnerChargingReport = useCallback(
        (value) => {
            if (searchText) {
                const ocpiPartnerChargingReportData = {
                    ...value,
                    search: searchText,
                    from: moment(startDate).utc(),
                    to: moment(endDate).utc(),
                    is_emsp_based_booking: false,
                };
                dispatch({
                    type: OCPI_BOOKING,
                    payload: ocpiPartnerChargingReportData,
                    cb: (res) => {
                        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
                            onCloseAdvanceFilterPopup();
                        }
                    },
                });
            } else if (!_.isEmpty(userProfileStations)) {
                const data = {
                    ...value,
                    from: moment(startDate).utc(),
                    to: moment(endDate).utc(),
                    charging_station: userProfileStations,
                    is_emsp_based_booking: false,
                };
                dispatch({
                    type: OCPI_BOOKING,
                    payload: data,
                    cb: (res) => {
                        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
                            onCloseAdvanceFilterPopup();
                        }
                    },
                });
            } else {
                const data = {
                    ...value,
                    from: moment(startDate).utc(),
                    to: moment(endDate).utc(),
                    is_emsp_based_booking: false,
                };
                dispatch({
                    type: OCPI_BOOKING,
                    payload: data,
                    cb: (res) => {
                        if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
                            onCloseAdvanceFilterPopup();
                        }
                    },
                });
            }
        },
        [startDate, endDate, searchText]
    );

    const dateFilter = useCallback(() => {
        if (sortByItem.item && sortByItem.order) {
            const filterDateData = {
                from: moment(startDate).utc(),
                to: moment(endDate).utc(),
                charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
            };
            getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
        } else if (!_.isEmpty(advanceFilterData)) {
            if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
                const filterData = {
                    ...advanceFilterData,
                    charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                    wallet_type: '',
                    type: '',
                };
                const data = {
                    ...filterData,
                    from: moment(startDate).utc(),
                    to: moment(endDate).utc(),
                };
                ocpiPartnerChargingReport(data);
            } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All') {
                const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', wallet_type: '' };
                const data = {
                    ...filterData,
                    from: moment(startDate).utc(),
                    to: moment(endDate).utc(),
                };
                ocpiPartnerChargingReport(data);
            } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.type === 'All') {
                const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', type: '' };
                const data = {
                    ...filterData,
                    from: moment(startDate).utc(),
                    to: moment(endDate).utc(),
                };
                ocpiPartnerChargingReport(data);
            } else if (advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
                const filterData = { ...advanceFilterData, wallet_type: '', type: '' };
                const data = {
                    ...filterData,
                    from: moment(startDate).utc(),
                    to: moment(endDate).utc(),
                };
                ocpiPartnerChargingReport(data);
            } else if (advanceFilterData.charging_station === 'All') {
                const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
                const data = {
                    ...filterData,
                    from: moment(startDate).utc(),
                    to: moment(endDate).utc(),
                };
                ocpiPartnerChargingReport(data);
            } else if (advanceFilterData.type === 'All') {
                const filterData = { ...advanceFilterData, type: '' };
                const data = {
                    ...filterData,
                    from: moment(startDate).utc(),
                    to: moment(endDate).utc(),
                };
                ocpiPartnerChargingReport(data);
            } else if (advanceFilterData.wallet_type === 'All') {
                const filterData = { ...advanceFilterData, wallet_type: '' };
                const data = {
                    ...filterData,
                    from: moment(startDate).utc(),
                    to: moment(endDate).utc(),
                };
                ocpiPartnerChargingReport(data);
            } else {
                const data = {
                    ...advanceFilterData,
                    from: moment(startDate).utc(),
                    to: moment(endDate).utc(),
                };
                ocpiPartnerChargingReport(data);
            }
        } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
            const filterDateData = {
                from: moment(startDate).utc(),
                to: moment(endDate).utc(),
                charging_station: userProfileStations,
            };
            ocpiPartnerChargingReport(filterDateData);
        } else {
            const filterDateData = {
                from: moment(startDate).utc(),
                to: moment(endDate).utc(),
            };
            ocpiPartnerChargingReport(filterDateData);
        }
    }, [startDate, endDate, sortByItem, advanceFilterData, searchText]);

    const searchHandler = (event) => {
        const value = event.target.value;
        setSearchText(value);
    };

    const handleSearch = () => {
        ocpiPartnerChargingReport({});
    };


    useEffect(() => {
        if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
            const data = {
                charging_station: userProfileStations,
            };
            ocpiPartnerChargingReport(data);
        } else {
            ocpiPartnerChargingReport();
        }
    }, []);

    const getSortByItem = useCallback(
        (name, order, page, filterDateData) => {
            const sortedOrder = order || 'asc';
            if (page && startDate && endDate) {
                const data = {
                    from: moment(startDate).utc(),
                    to: moment(endDate).utc(),
                    sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
                    page: page.selected + 1,
                    charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                endDate && ocpiPartnerChargingReport(data);
            } else if (page) {
                const data = {
                    sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
                    page: page.selected + 1,
                    charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                ocpiPartnerChargingReport(data);
            } else if (filterDateData) {
                const data = {
                    ...filterDateData,
                    sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
                    charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                endDate && ocpiPartnerChargingReport(data);
            } else {
                const data = {
                    sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
                    charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                ocpiPartnerChargingReport(data);
            }
        },
        [startDate, endDate]
    );

    const handlePageClick = useCallback(
        (page) => {
            if (sortByItem.item && sortByItem.order) {
                getSortByItem(sortByItem.item, sortByItem.order, page);
            } else if (!_.isEmpty(advanceFilterData)) {
                if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
                    const filterData = {
                        ...advanceFilterData,
                        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                        wallet_type: '',
                        type: '',
                    };
                    const data = {
                        ...filterData,
                        page: page.selected + 1,
                        from: moment(startDate).utc(),
                        to: moment(endDate).utc(),
                    };
                    ocpiPartnerChargingReport(data);
                } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.wallet_type === 'All') {
                    const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', wallet_type: '' };
                    const data = {
                        ...filterData,
                        page: page.selected + 1,
                        from: moment(startDate).utc(),
                        to: moment(endDate).utc(),
                    };
                    ocpiPartnerChargingReport(data);
                } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.type === 'All') {
                    const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '', type: '' };
                    const data = {
                        ...filterData,
                        page: page.selected + 1,
                        from: moment(startDate).utc(),
                        to: moment(endDate).utc(),
                    };
                    ocpiPartnerChargingReport(data);
                } else if (advanceFilterData.wallet_type === 'All' && advanceFilterData.type === 'All') {
                    const filterData = { ...advanceFilterData, wallet_type: '', type: '' };
                    const data = {
                        ...filterData,
                        page: page.selected + 1,
                        from: moment(startDate).utc(),
                        to: moment(endDate).utc(),
                    };
                    ocpiPartnerChargingReport(data);
                } else if (advanceFilterData.charging_station === 'All') {
                    const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
                    const data = {
                        ...filterData,
                        page: page.selected + 1,
                        from: moment(startDate).utc(),
                        to: moment(endDate).utc(),
                    };
                    ocpiPartnerChargingReport(data);
                } else if (advanceFilterData.type === 'All') {
                    const filterData = { ...advanceFilterData, type: '' };
                    const data = {
                        ...filterData,
                        page: page.selected + 1,
                        from: moment(startDate).utc(),
                        to: moment(endDate).utc(),
                    };
                    ocpiPartnerChargingReport(data);
                } else if (advanceFilterData.wallet_type === 'All') {
                    const filterData = { ...advanceFilterData, wallet_type: '' };
                    const data = {
                        ...filterData,
                        page: page.selected + 1,
                        from: moment(startDate).utc(),
                        to: moment(endDate).utc(),
                    };
                    ocpiPartnerChargingReport(data);
                } else {
                    const data = {
                        ...advanceFilterData,
                        page: page.selected + 1,
                        from: moment(startDate).utc(),
                        to: moment(endDate).utc(),
                    };
                    ocpiPartnerChargingReport(data);
                }
            } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
                const filterDateData = {
                    from: moment(startDate).utc(),
                    to: moment(endDate).utc(),
                    charging_station: userProfileStations,
                    page: page.selected + 1,
                };
                ocpiPartnerChargingReport(filterDateData);
            } else if (startDate && endDate && searchText) {
                const data = {
                    from: moment(startDate).utc(),
                    to: moment(endDate).utc(),
                    search: searchText,
                    page: page.selected + 1,
                };
                ocpiPartnerChargingReport(data);
            } else if (startDate && endDate) {
                const data = {
                    from: moment(startDate).utc(),
                    to: moment(endDate).utc(),
                    page: page.selected + 1,
                };
                ocpiPartnerChargingReport(data);
            } else {
                const data = {
                    page: page.selected + 1,
                };
                ocpiPartnerChargingReport(data);
            }
        },
        [sortByItem, startDate, endDate, advanceFilterData, searchText]
    );
    // Download Excel Functionality

    const downloadocpiPartnerChargingReport = useCallback(() => {
        let filterData = { ...advanceFilterData };

        // Check if charging station filter needs to be adjusted
        if (advanceFilterData.charging_station === 'All' && !_.isEmpty(userProfileStations)) {
            filterData = { ...filterData, charging_station: userProfileStations };
        }

        const data = {
            ...filterData,
            excel: true,
            from: moment(startDate).utc(),
            to: moment(endDate).utc(),
            is_emsp_based_booking: false,
        };

        dispatch({
            type: DOWNLOAD_OCPI_BOOKING,
            payload: data,
            cb: (res) => {
                if (res && _.get(res, 'status') === 200) {
                    fileDownload(res.data, `${'OCPI Parntner Charging Report'}.xlsx`);
                }
            },
        });
    }, [startDate, endDate, advanceFilterData, userProfileStations]);

    const downloadFile = () => {
        downloadocpiPartnerChargingReport();
    };

    const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

    const search = (
        <SearchBox
            preIcon={<BsSearch />}
            value={searchText}
            onChange={searchHandler}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    handleSearch();
                }
            }}
        />
    );
    const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;

    const allStation = useSelector((state) => state.chargingStation.chargingStations);
    const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
    const stationList = allStation.filter((x) => userProfileStations.indexOf(x.id) !== -1);

    const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { charging_station: '', wallet_type: '', type: '' };

    const getAllStation = useCallback((data = {}) => {
        const stationData = {
            ...data,
            request_all: true,
        };
        dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
    }, []);


    const getOcpidata = useCallback(
        (data) =>
            dispatch({
                type: FETCH_CPO,
                payload: data,
            }),
        []
    );

    useEffect(() => {
        getAllStation();
        getOcpidata({ limit: 999 });
    }, []);

    // const allWalletType = [
    //   { label: 'Book', value: 'Book' },
    //   { label: 'Booking Cancellation', value: 'Booking Cancellation' },
    //   { label: 'CMS Credit', value: 'CMS CREDIT' },
    //   { label: 'Refund', value: 'Refund' },
    //   { label: 'Online Credit', value: 'WalletTopup' },
    //   { label: 'Cash Back', value: 'Cashback' },
    //   { label: 'Extra Unit Consumed', value: 'ExtraUnits' },
    // ];

    // const allPaymentType = [
    //   { label: 'Debit', value: 'debit' },
    //   { label: 'Credit', value: 'credit' },
    // ];

    
    function formatDateString(dateString) {
        // Create a new Date object from the input string
        const date = new Date(dateString);
    
        // Extract the date components
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
    
        // Extract the time components
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
    
        // Format the date and time parts
        const formattedDate = `${day}/${month}/${year}`;
        const formattedTime = `${hours}:${minutes}:${seconds}`;
    
        // Combine the date and time parts
        return `${formattedDate}, ${formattedTime}`;
    }
    


    return (
        <>
            <LayoutContainer>
                <AdminHeader title={t('header.ocpiCpoPartnerChargingReport')} />
                <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
                    <div className="page-content-wrapper scrollable">
                        <div className="data-report--main">
                            <Card>
                                <div className="data-report__inner">
                                    <div className="data-report__box">
                                        <Row className="data-report__row">
                                            <Col
                                                xl={`${showSearchBar ? 'auto' : 'auto'}`}
                                                md={`${showSearchBar ? 'auto' : 'auto'}`}
                                                className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                                                style={{ display: 'none' }}
                                            >
                                                {showSearchBar ? search : searchIcon}
                                            </Col>
                                            <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                                                <div className="data-report-date_picker">
                                                    <DateTimePicker
                                                        onChangeOfStartDate={(item) => setStartDate(item)}
                                                        onChangeOfEndDate={(item) => setEndDate(item)}
                                                        initialValueOfStartDate={moment().startOf('month')}
                                                        initialValueOfEndDate={moment(new Date()).endOf('day')}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl={'auto'} md={'auto'}>
                                                <div className="report-search-box" onClick={dateFilter}>
                                                    <Button className="report-search-button">{t('button.submit')}</Button>
                                                </div>
                                            </Col>
                                            <Col xl={'auto'} md={'auto'}>
                                                <div className="report-search-excel-block">
                                                    <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                                                        <Button className="report-search-button">
                                                            <FaFilter className="hide-lap" />
                                                            <span>{t('button.advancedFilter')}</span>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={downloadFile}>
                                                {isLoading ? (
                                                    <div className="loader--block">
                                                        <Spinner as="span" animation="border" size="sm" role="status" />
                                                    </div>
                                                ) : (
                                                    <div className="report-excel-icon">
                                                        <BsDownload title="Download" size={28} color={'#3c7cdd'} />
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="data-report__table">
                                        <div className="table-responsive">
                                            <table className="record-list-table" id="table-to-xls">
                                                <thead>
                                                    <tr>

                                                        <th>
                                                            <div className="sorting">
                                                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ocpiPartnerChargingReport.srNo')}</span>
                                                            </div>
                                                        </th>

                                                        <th>
                                                            <div className="sorting">
                                                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ocpiPartnerChargingReport.stationName')}</span>
                                                            </div>
                                                        </th>

                                                        <th>
                                                            <div className="sorting">
                                                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ocpiPartnerChargingReport.chargerName')}</span>
                                                            </div>
                                                        </th>

                                                        <th>
                                                            <div className="sorting">
                                                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ocpiPartnerChargingReport.connectorName')}</span>
                                                            </div>
                                                        </th>

                                                        <th>
                                                            <div className="sorting">
                                                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ocpiPartnerChargingReport.partyId')}</span>
                                                            </div>
                                                        </th>

                                                        <th>
                                                            <div className="sorting">
                                                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ocpiPartnerChargingReport.ocpiPartner')}</span>
                                                            </div>
                                                        </th>

                                                        <th>
                                                            <div className="sorting">
                                                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ocpiPartnerChargingReport.unitConsumed')}</span>
                                                            </div>
                                                        </th>

                                                        <th>
                                                            <div className="sorting">
                                                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ocpiPartnerChargingReport.idTag')}</span>
                                                            </div>
                                                        </th>

                                                        <th>
                                                            <div className="sorting">
                                                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ocpiPartnerChargingReport.transactionID')}</span>
                                                            </div>
                                                        </th>

                                                        <th>
                                                            <div className="sorting">
                                                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ocpiPartnerChargingReport.sessionId')}</span>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="sorting">
                                                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ocpiPartnerChargingReport.startTime')}</span>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="sorting">
                                                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ocpiPartnerChargingReport.stopTime')}</span>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="sorting">
                                                                <span>{isLoader ? <Skeleton height={15} width={100} /> : t('ocpiPartnerChargingReport.sessionDuration')}</span>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {isLoader ? (
                                                        <tr>
                                                            <td colSpan={11} className="border-0">
                                                                <div className="loader--block">
                                                                    <Skeleton height={15} width={100} />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : _.isEmpty(ocpidatalist) ? (
                                                        <tr>
                                                            <td colSpan={10} className="border-0">
                                                                <div className="empty-data-block">{'No Records Found'}</div>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        !(isLoader || _.isUndefined(isLoader)) &&
                                                        _.map(ocpidatalist, (item, key) => {
                                                            const serial_num = limit * (page - 1) + key;
                                                            return (
                                                                <>
                                                                    <tr key={`wallet-transaction-report-${serial_num}`}>
                                                                        <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                                                                        <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.name', '-')}</td>
                                                                        <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charger_id', '-')}</td>
                                                                        <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'connectorId', '-')}</td>
                                                                        <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'ocpiCredential.partyId', '-')}</td>
                                                                        <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'ocpiCredential.roles[0].business_details.name', '-')}</td>
                                                                        <td>{isLoader ? <Skeleton height={15} width={100} /> : (_.get(item, 'meterstop', '-') - _.get(item, 'meterstart', '-'))/1000}</td>
                                                                        <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'idTag', '-')}</td>
                                                                        <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'transaction_id', '-')}</td>
                                                                        <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'id', '-')}</td>
                                                                        <td>
                                                                            {isLoader ? (
                                                                                <Skeleton height={15} width={100} />
                                                                            ) : (
                                                                                formatDateString(_.get(item, 'booking_start', '-'))
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {isLoader ? (
                                                                                <Skeleton height={15} width={100} />
                                                                            ) : (
                                                                                formatDateString(_.get(item, 'booking_stop', '-'))
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {!isLoader &&
                                                                                `${Math.floor(
                                                                                    (new Date(_.get(item, 'booking_stop')) - new Date(_.get(item, 'booking_start'))) / (1000 * 60 * 60)
                                                                                )}:${Math.floor(
                                                                                    ((new Date(_.get(item, 'booking_stop')) - new Date(_.get(item, 'booking_start'))) % (1000 * 60 * 60)) /
                                                                                    (1000 * 60)
                                                                                )}:${Math.floor(
                                                                                    ((new Date(_.get(item, 'booking_stop')) - new Date(_.get(item, 'booking_start'))) % (1000 * 60)) / 1000
                                                                                )}`}
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(ocpidatalist) && (
                                        <ReactPagination
                                            currentPage={page}
                                            limit={limit}
                                            total={totalData}
                                            handlePageClick={(pageVal) => handlePageClick(pageVal)}
                                            totalPages={totalPages}
                                            marginPagesDisplayed={1}
                                        />
                                    )}
                                </div>
                            </Card>
                        </div>
                    </div>
                </SkeletonTheme>
                {currentUserName === 'CZ Tenant Admin' ? null : <Footer />}
            </LayoutContainer>

            {/* Report Advance Filter Functionality */}

            {showAdvanceFilterPopup && (
                <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
                    <Formik
                        enableReinitialize={!_.isEmpty(advanceFilterData)}
                        initialValues={initialValues}
                        onSubmit={(values, { setSubmitting }) => {
                            !values.charging_station && delete values['charging_station'];
                            !values.wallet_type && delete values['wallet_type'];
                            !values.type && delete values['type'];
                            if (values.charging_station === 'All' && values.wallet_type === 'All' && values.type === 'All') {
                                const ocpiPartnerChargingReportData = {
                                    ...values,
                                    charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                                    wallet_type: '',
                                    type: '',
                                };
                                ocpiPartnerChargingReport(ocpiPartnerChargingReportData);
                            } else if (values.charging_station === 'All' && values.wallet_type === 'All') {
                                const ocpiPartnerChargingReportData = {
                                    ...values,
                                    charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                                    wallet_type: '',
                                };
                                ocpiPartnerChargingReport(ocpiPartnerChargingReportData);
                            } else if (values.charging_station === 'All' && values.type === 'All') {
                                const ocpiPartnerChargingReportData = {
                                    ...values,
                                    charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                                    type: '',
                                };
                                ocpiPartnerChargingReport(ocpiPartnerChargingReportData);
                            } else if (values.wallet_type === 'All' && values.type === 'All') {
                                const ocpiPartnerChargingReportData = { ...values, wallet_type: '', type: '' };
                                ocpiPartnerChargingReport(ocpiPartnerChargingReportData);
                            } else if (values.charging_station === 'All') {
                                const ocpiPartnerChargingReportData = { ...values, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
                                ocpiPartnerChargingReport(ocpiPartnerChargingReportData);
                            } else if (values.wallet_type === 'All') {
                                const ocpiPartnerChargingReportData = { ...values, wallet_type: '' };
                                ocpiPartnerChargingReport(ocpiPartnerChargingReportData);
                            } else if (values.type === 'All') {
                                const ocpiPartnerChargingReportData = { ...values, type: '' };
                                ocpiPartnerChargingReport(ocpiPartnerChargingReportData);
                            } else {
                                ocpiPartnerChargingReport(values);
                            }
                            handleAdvanceFilterData(values);
                            setSubmitting(false);
                        }}
                    >
                        {({ values, handleSubmit, setFieldValue, resetForm }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className="mb-5">
                                    <div className="canvas-selection__block">
                                        <Select
                                            label={t('filters.station')}
                                            options={
                                                !_.isEmpty(stationList)
                                                    ? !changeStation
                                                        ? !_.isEmpty(stationList) && [
                                                            { label: 'All', value: 'All' },
                                                            ..._.map(stationList, (station) => {
                                                                return { label: station.name, value: station.id };
                                                            }),
                                                        ]
                                                        : !_.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                                                    : !changeStation
                                                        ? !_.isEmpty(allStation) && [
                                                            { label: 'All', value: 'All' },
                                                            ..._.map(allStation, (station) => {
                                                                return { label: station.name, value: station.id };
                                                            }),
                                                        ]
                                                        : !_.isEmpty(allStation) && [{ label: 'All', value: 'All' }]
                                            }
                                            placeholder={t('placeHolder.selectStation')}
                                            name="charging_station"
                                            isMulti
                                            value={values.charging_station}
                                            onMenuScrollDown={true}
                                            onChange={(val) => {
                                                if (_.includes(val, 'All')) {
                                                    setChangeStation(true);
                                                    setFieldValue(`charging_station`, 'All');
                                                } else if (_.isEmpty(val)) {
                                                    setFieldValue(`charging_station`, '');
                                                    setChangeStation(false);
                                                } else {
                                                    setChangeStation(false);
                                                    setFieldValue('charging_station', val);
                                                }
                                            }}
                                        />


                                        <Select
                                            label={t('filters.ocpiParties')}
                                            options={[
                                                ..._.map(ocpidata, (data) => {
                                                    return { label: data.partyId, value: data.partyId };
                                                }),
                                            ]}
                                            placeholder={t('filters.selectParty')}
                                            name="partyId"
                                            onMenuScrollDown={true}
                                            getDataOnScrollDown={getOcpidata}
                                            page={ocpiPage}
                                            totalPage={ocpiTotalPages}
                                            value={values.partyId}
                                            onChange={(val) => {
                                                setFieldValue('partyId', val);
                                            }}
                                        />

                                    </div>

                                </div>
                                <div className="canvas-btn__block">
                                    <Button type="submit" className="apply--btn w-100">
                                        {t('button.applyFilters')}
                                    </Button>
                                    <div
                                        className="reset--block"
                                        onClick={() => {
                                            setAdvanceFilterData({});
                                            ocpiPartnerChargingReport();
                                            resetForm();
                                            onCloseAdvanceFilterPopup();
                                        }}
                                    >
                                        <MdClear size={24} className="reset--icon" />
                                        {t('button.clear')}
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </OffCanvas>
            )}
        </>
    );
};

export default ocpiPartnerChargingReport;